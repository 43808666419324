import React from 'react'
import { graphql } from 'gatsby'

import { renderAst } from '../util'
import Layout from '../layouts'
import SEO from '../components/SEO'
import Article from '../components/Article'
import Container from '../components/Container'
import Disqus from '../components/Disqus'
import './page.scss'

class PageTemplate extends React.Component {
  render () {
    const page = this.props.data.markdownRemark

    return (
      <Layout location={this.props.location} siteMeta={this.props.data.site.siteMetadata}>
        <SEO
          title={page.frontmatter.title}
          description={page.frontmatter.description || page.excerpt}
          image={page.frontmatter.featured_image ? page.frontmatter.featured_image.childImageSharp.fluid.src : null}
          pathname={page.fields.slug}
          article={true}
        />
        <main className="page-main-content">
          <Container>
            <div className="single-content">
              <Article post={page} content={renderAst(page.htmlAst)} siteMeta={this.props.data.site.siteMetadata} />
              <Disqus location={this.props.location} disqusShortname={this.props.data.site.siteMetadata.disqusShortname} />
            </div>
          </Container>
        </main>
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        name
        title
        description
        about
        author
        disqusShortname
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      timeToRead
      htmlAst
      fields {
        slug
      }
      frontmatter {
        title
        description
        date(formatString: "MMMM DD, YYYY")
        featured_image {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { type: { eq: "post" } } }
    ) {
      edges {
        node {
          excerpt
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            type
            description
            featured_image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
